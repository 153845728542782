<template>
  <div>
    <div class='relative'>
      <ContentBackgroundCover background-color='#FAFAFA'/>
      <div v-if='isMobile'>
        <qr-scanner 
          :key='scannerKey'
          :scan-helper-message='scannerHelperText'
          @scanned-qr='scannedNewQrCode' />
      </div>
      <div v-else
        class='p-24 text-center text-lg'>
        Please scan QR codes on your mobile device.
      </div>
    </div>
    <div class='py-4 bg-gray-50'>
      <h1 class='text-center text-xl font-semibold my-8 uppercase'>Stamps Collected</h1>
      <div class='flex flex-row flex-wrap gap-2 lg:gap-4 justify-center items-center px-4 py-4'>
        <div v-for='(stamp, index) in possibleStamps' :key='`stamp-${index}`'
          class='gbcc-event-stamp-size'>
          <div class='text-center'>
            <img 
              class='rounded-full h-20 w-20 mx-auto'
              :class='stampedClass(stamp)'
              src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/gbcc_stamp_1.png' />
            <div class='text-xs truncate text-center mt-1'>{{ stamp.sponsorName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if='showOverlay'
      class='w-screen h-screen z-30 fixed top-0 left-0'>
      <div class='w-full h-full bg-gray-900 opacity-80 z-0'>
        <!-- overlay -->
      </div>
      <div class='z-10 absolute text-white' style='top:50%; left:calc(50% - 120px); width:240px;'>
        <loading-spinner v-if='showLoadingSpinner' class='block mx-auto' />
        <div v-if='showConfirmedStamp'
          class='w-full text-center'>
          <h1 
            class='text-lg mb-4 text-white font-medium'
            style='text-shadow: 2px 2px #00000055;'>{{ confirmationMessage }}</h1>
          <button 
            @click='resetStamp'
            class='block px-8 py-3 w-full rounded-md text-white uppercase font-semibold'
            style='background-color:var(--eventMainColor)'>
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrScanner              from '@/components/QrScanner.vue'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'StampEvent',
  components: {
    ContentBackgroundCover,
    LoadingSpinner,
    QrScanner,
  },
  data () {
    return {
      showLoadingSpinner: false,
      showConfirmedStamp: false,
      stamped: [],
      stampedSponsorName: '',
      scannerKey: 0,
    }
  },
  computed: {
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone') ||
             window.navigator.userAgent.includes('iPad')
    },
    emptyStamp () {
      return {
        date: '',
        sponsorName: '',
        sponsorId: null,
      }
    },
    possibleStamps () {
      let stampedCount = this.stamped.length
      let countOfStamps = 20
      let emptyStamps = countOfStamps - stampedCount
      let arrOfEmptyStamps = Array.from({ length: emptyStamps }, () => cloneDeep(this.emptyStamp))
      return [...this.stamped, ...arrOfEmptyStamps]
    },
    scannerHelperText () { // TODO (Hana): should prob make a config
      return 'Please use the camera on your mobile to scan and collect stamps.'
    },
    showOverlay () {
      return this.showLoadingSpinner || this.showConfirmedStamp // or other stuff we wants
    },
    validationStrings () {
      return [
        'gbcc-onsite',
        'sponsorId',
      ]
    },
    confirmationMessage () {
      return `You have collected a stamp for ${this.stampedSponsorName}!`
    },
    sponsorIdFromQueryParam () {
      return this.$route.query.sponsorId
    },
  },
  methods: {
    ...mapActions('sponsors', [
      'getStampedSponsors',
      'stampSponsor',
    ]),
    scannedNewQrCode (qrCode) {
      console.log('scannedNewQrCode:', qrCode)
      const urlSearchParams = new URLSearchParams((new URL(qrCode)).search)
      let sponsorId = urlSearchParams.get('sponsorId')
      this.checkAlreadyStamped(sponsorId)
    },
    checkAlreadyStamped (sponsorId) {
      let found = this.stamped.find(stamp => stamp.sponsorId === parseInt(sponsorId))
      if (found) {
        alert('You have already collected this stamp.')
      } else {
        this.setStampForSponsor(sponsorId)
      }
    },
    setStampForSponsor (sponsorId) {
      this.showLoadingSpinner = true
      this.stampSponsor(sponsorId).then(resp => {
        console.log('stampSponsor: ', resp)
        this.getStampedSponsors().then(stampedSponsors => {
          this.showConfirmedStamp = true
          this.showLoadingSpinner = false
          this.stamped = stampedSponsors
          let justStamped = stampedSponsors.find(stamp => stamp.sponsorId === parseInt(sponsorId))
          this.stampedSponsorName = justStamped.sponsorName
          this.scannerKey += 1
        })
      })
    },
    stampedClass (stamp) {
      if (stamp.sponsorId) {
        return 'border-4 border-pink-500 shadow-md'
      } else {
        return 'border-4 opacity-20'
      }
    },
    resetStamp () {
      this.showConfirmedStamp = false
      this.stampedSponsorName = ''
    },
  },
  mounted () {
    this.getStampedSponsors().then(resp => {
      this.stamped = resp
      if (this.sponsorIdFromQueryParam) {
        this.checkAlreadyStamped(parseInt(this.sponsorIdFromQueryParam))
      }
    })
  },
}
</script>

<style lang='scss' scoped>
.gbcc-event-stamp-size {
  width: calc(100% * (1/4) - 0.5rem - 1px);
}

@media (min-width: 1024px) { 
  .gbcc-event-stamp-size {
    width: calc(100% * (1/10) - 1rem - 1px);
  }
}

</style>
